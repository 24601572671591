import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@rmwc/icon';

import { UserContext } from '../../../context/user-context';
import { fetchApi } from '../../../fetch';
import { reformatDanePodmiotu, serializeAuditData } from '../../../helpers';
import {
    getSerializedObject,
    serializeObject,
    translateErrorMessage
} from '../../../serializers';
import {
    AuditData,
    Box,
    ButtonStyled,
    Container,
    DialogCustom,
    FieldWithLabel,
    GridCustom,
    Message,
    OfferStatus,
    Text
} from '../../common';
import { BaseComponent, DanePodmiotu, ZalacznikiSelect } from '../common';


class Oferta extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        this.xhrFetch = null;
        this.setState(
            {zamowienia: (serializeObject(data.advertisement).zamowienia || []).map(z => {
                return {
                    id: z.id,
                    zamowienieNode: z.zamowienieNode,
                    tytul: z.tytul,
                    czyWariantowe: z.czyWariantowe,
                }
            })},
            this.getOfferData
        );
    }

    handleFetchOfferDataSuccess = (data) => {
        this.xhrFetch = null;
        data = getSerializedObject(
            data, {addTemporaryId: true}).pakietOfert;
        if (!data) {
            this.setState({
                initializingOn: false,
                fetchError: 'Nie udało się pobrać danych oferty.',
            });
        } else {
            this.setState({
                initializingOn: false,
                danePodmiotu: data.danePodmiotu ? reformatDanePodmiotu(data.danePodmiotu) : {},
                oferty: (data.oferty || []).map(o => {
                    o.zamowienieNode = o.zamowienieNode.id;
                    return o
                }),
                zalaczniki: data.zalaczniki || [],
                id: data.id,
                status: data.status,
                auditData: serializeAuditData(data),
            });
        }
    }

    handleCloseWithdrawingDialog = () => {
        this.setState({withdrawingOn: false});
    }

    handleWithdraw = () => {
        if (this.props.preview) { return }

        this.xhrFetch = fetchApi(
            `/api/offerset/announcement/${this.props.ogloszenieId}/withdraw`,
            'PUT',
            {},
            {},
            this.handleFetchWithdrawSuccess,
            this.handleFetchWithdrawError,
            this.handleFetchWithdrawIncorrectStatus
        );
    }

    handleFetchWithdrawSuccess = (data) => {
        this.xhrFetch = null;
        this.props.onWithdrawOferta();
    }

    handleFetchWithdrawError = (data) => {
        this.xhrFetch = null;
        this.setState({
            withdrawingOn: false,
            fetchWithdrawError: `Nie udało się wycofać oferty. ${translateErrorMessage(data.message)}`,
        });
    }

    handleFetchWithdrawIncorrectStatus = (status) => {
        this.xhrFetch = null;
        this.setState({
            withdrawingOn: false,
            fetchWithdrawError: `Nie udało się wycofać oferty. Wystąpił błąd o kodzie ${status}.`,
        });
    }

    // helpers

    getAdditionalState(props) {
        const commonState = {
            zamowienia: [],
            danePodmiotu: {},
            oferty: [],
            zalaczniki: [],
            auditData: {},
        };
        if (this.props.preview) {
            return commonState
        }
        return {
            ...commonState,
            withdrawingOn: false,
            fetchWithdrawError: '',
        }
    }

    getFetchUrl() {
        return `/api/announcements/${this.props.ogloszenieId}`
    }

    getFetchError(message) {
        message = super.getFetchError(message);
        return `Nie udało się pobrać danych oferty. ${(message)}`
    }

    getFetchIncorrectStatusError(status) {
        return `Nie udało się pobrać danych oferty. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    getOfferData = () => {
        let url;
        if (this.props.ofertaId) {
            url = `/api/offerset/${this.props.ofertaId}`;
        } else {
            url = `/api/offerset/announcement/${this.props.ogloszenieId}`;
        }
        this.xhrFetch = fetchApi(
            url,
            'GET',
            {},
            {},
            this.handleFetchOfferDataSuccess,
            this.handleFetchError,
            this.handleFetchIncorrectStatus,
        );
    }

    // rendering

    renderFetchError() {
        if (this.props.preview) {
            return (
                <Message
                    error
                    id="sessionDialogLabel"
                    messageIcon="report_problem"
                    messageTitle={this.state.fetchError}>
                    <ButtonStyled primary onClick={this.props.onClosePreview}>OK</ButtonStyled>
                </Message>
            )
        }
        return super.renderFetchError()
    }

    renderHeader() {
        if (this.state.initializingOn || this.state.fetchError.length) { return null }

        const {label, nazwa} = this.state.status;

        return (
            <>
                {this.props.preview && (
                    <ButtonStyled
                        className="dialog-long-content__close"
                        primary
                        onClick={this.props.onClosePreview}>
                        Zamknij podgląd
                    </ButtonStyled>
                )}
                <OfferStatus
                    statusIcon={label === 'DRAFT' ? 'assignment' : (label === 'SUBMITTED' ? 'assignment_turned_in' : 'assignment_return')}
                    statusName={nazwa} />
                <Text tag="h2" mainHeader>Podgląd oferty</Text>
            </>
        )
    }

    renderContent() {
        return (
            <>
                {this.renderDanePodmiotu()}
                {this.renderOferty()}
                {this.renderZalaczniki()}
                {!this.props.preview && (
                    <>
                        {this.renderActionsButtons()}
                        {this.state.withdrawingOn && this.renderWithdrawDialog()}
                    </>
                )}
                {this.state.danePodmiotu.ktoUtworzyl.id === this.context.user.id &&
                    <AuditData {...this.state.auditData} />
                }
            </>
        )
    }

    renderDanePodmiotu() {
        let content;
        if (!Object.keys(this.state.danePodmiotu).length) {
            content = (
                <Text error>
                    Nie zdefiniowano danych podmiotu dla użytkownika. Aby je ustawić, <Link to="/uzytkownicy/moj-profil" className="link-text">przejdź do swojego profilu</Link>.
                </Text>
            );
        } else {
            content = (
                <>
                    <DanePodmiotu danePodmiotu={this.state.danePodmiotu} />
                    <Text info>Te dane podmiotu zostaną dołączone do oferty.</Text>
                </>
            );
        }
        return (
            <Container>
                <Text tag="h2" mainHeader>Dane podmiotu</Text>
                {content}
            </Container>
        )
    }

    renderOferty() {
        const zamowienia = this.state.zamowienia;
        return (
            <Container className="details-preview separate-container">
                <GridCustom flexEnd fullwidth>
                    <Text tag="h2" mainHeader>Dane oferty</Text>
                </GridCustom>
                {zamowienia.length > 1 ? (
                    zamowienia.map(z => this.renderMultiplyOferta(z))
                ) : this.renderSingleOferta()}
            </Container>
        )
    }

    renderMultiplyOferta(zamowienie) {
        const oferta = this.state.oferty.filter(o => o.zamowienieNode === zamowienie.zamowienieNode.id)[0] || null;
        if (!oferta) {
            return (
                <div key={zamowienie.id} className="offer__part-name offer__part-name--offer-details offer__part-name--no-offer">
                    <Text className="text--btn"><Icon aria-hidden="true" icon="cancel" /> Oferta częściowa do części</Text>
                    <Text label>{zamowienie.tytul} <span>- brak</span></Text>
                </div>
            )
        }
        return (
            <Container key={oferta.id} fullwidth>
                <GridCustom flexEnd fullwidth>
                    <div className="offer__part-name offer__part-name--offer-details">
                        <Text className="text--btn"><Icon aria-hidden="true" icon="check_box" /> Oferta częściowa do części</Text>
                        <Text label>{zamowienie.tytul}</Text>
                    </div>
                    {oferta.czyWariantowe && <Text className="text--btn"><Icon aria-hidden="true" icon="check_box" /> Oferta wariantowa</Text>}
                </GridCustom>
                {oferta.czyWariantowe ? this.renderWariantowa(oferta) : this.renderNiewariantowa(oferta)}
            </Container>
        )
    }

    renderWariantowa(oferta) {
        return (
            <>
                <Text tag="h2" sectionHeader>Lista wariantów</Text>
                <Text info>Liczba wyników: {oferta.warianty.length}</Text>
                <GridCustom tag="ul">
                    {oferta.warianty.map(w => (
                        <Box key={w.id} tag="li" titleOneRow={`${w.cena} PLN`} flexiblewidth>
                            {w.opis.length > 0 && (
                                <FieldWithLabel label="Opis">
                                    <Text className="long-text">{w.opis}</Text>
                                </FieldWithLabel>
                            )}
                        </Box>
                    ))}
                </GridCustom>
            </>
        )
    }

    renderNiewariantowa(oferta) {
        return (
            <FieldWithLabel label="Cena">
                <Text>{oferta.warianty[0].cena} PLN</Text>
            </FieldWithLabel>
        )
    }

    renderSingleOferta() {
        const oferta = this.state.oferty[0];
        return (
            <Container key={oferta.id} fullwidth>
                <GridCustom flexEnd fullwidth>
                    <Text className="text--btn"><Icon aria-hidden="true" icon="check_box" /> Oferta do ogłoszenia</Text>
                    {oferta.czyWariantowe && <Text className="text--btn"><Icon aria-hidden="true" icon="check_box" /> Oferta wariantowa</Text>}
                </GridCustom>
                {oferta.czyWariantowe ? this.renderWariantowa(oferta) : this.renderNiewariantowa(oferta)}
            </Container>
        )
    }

    renderZalaczniki() {
        const { zalaczniki, id } = this.state;
        return (
            <Container className="details-preview separate-container">
                <Text tag="h2" sectionHeader>Załączniki</Text>
                <Text info className="text--counter">Liczba wyników: {zalaczniki.length}</Text>
                <ZalacznikiSelect zalaczniki={zalaczniki} offersetId={id} />
            </Container>
        )
    }

    renderActionsButtons() {
        if (this.state.status.label !== 'SUBMITTED') { return null }
        const withdrawingError = this.state.fetchWithdrawError;
        return (
            <>
                <GridCustom flexEnd>
                    <ButtonStyled
                        lite
                        onClick={() => this.setState({withdrawingOn: true, fetchWithdrawError: ''})}>
                        Wycofaj ofertę
                    </ButtonStyled>
                </GridCustom>
                <GridCustom flexEnd>
                    {withdrawingError.length > 0 && <Text error>{withdrawingError}</Text>}
                </GridCustom>
            </>
        )
    }

    renderWithdrawDialog() {
        return (
            <DialogCustom 
                dialogTitle="Czy na pewno chcesz wycofać tę ofertę?"
                onClose={this.handleCloseWithdrawingDialog}
            >
                <GridCustom flexEnd>
                    <ButtonStyled cancel onClick={this.handleCloseWithdrawingDialog}>Nie</ButtonStyled>
                    <ButtonStyled primary onClick={this.handleWithdraw}>Tak</ButtonStyled>
                </GridCustom>
            </DialogCustom>
        )
    }
}


Oferta.contextType = UserContext;

export { Oferta };
