import React from 'react';

import { getSerializedObject, translateErrorMessage } from '../../../serializers';
import { ButtonStyled, GridCustom, Text } from '../../common';
import { BaseComponent } from '../common';
import { FormRozstrzygniecie } from '../ogloszenia';
import { OfertaBox } from './OfertaBox';
import { OfertaPreview } from './OfertaPreview';


class OfertyOwnerClosedStatus extends BaseComponent {
    showAttachments = true;
    showFullVariants = true;

    // handlers

    handleFetchSuccess(data) {
        this.xhrFetch = null;
        const serializedData =  getSerializedObject(data);

        if (this.props.setAdvertisementManagerType && data.extra && Object.keys(data.extra).length) {
            this.props.setAdvertisementManagerType({
                isAuthor: data.extra.is_advertiser, isAdmin: data.extra.is_admin
            });
        }

        this.setState({
            initializingOn: false,
            pakietyOfert: serializedData.pakietyOfert,
        });
    }

    handleMyOfferButtonClick = () => {
        if (this.props.isAdvertisementOwner && !this.props.onlyAdmin) { return }
        this.setState({previewOn: true});
    }

    handleClosePreview = () => {
        this.setState({previewOn: false});
    }

    // helpers

    getAdditionalState(props) {
        return {
            formOn: false,
            previewOn: false,
            pakietyOfert: [],
        }
    }

    getFetchUrl() {
        return `/api/offerset/announcement/${this.props.ogloszenie.ogloszenie.id}/list`
    }

    getFetchError(message) {
        return `Nie udało się pobrać danych ofert. ${translateErrorMessage(message)}`
    }

    getFetchIncorrectStatusError(status) {
        return `Nie udało się pobrać danych ofert. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    sortOferty(oferta1, oferta2) {
        return oferta1.danePodmiotu.nazwa.localeCompare(oferta2.danePodmiotu.nazwa, 'pl')
    }

    sortWarianty(wariant1, wariant2) {
        return wariant1.cena - wariant2.cena
    }

    // rendering

    renderElements() {
        const isAdvertisementOwner = this.props.isAdvertisementOwner;
        if (isAdvertisementOwner && this.state.formOn) {
            return <FormRozstrzygniecie {...this.props} isNew={true} pakietyOfert={this.state.pakietyOfert} />
        }
        const onlyAdmin = this.props.onlyAdmin;
        return (
            <>
                {onlyAdmin && this.renderMyOfferButton()}
                {this.renderContent()}
                {isAdvertisementOwner && (
                    <GridCustom flexEnd>
                        <ButtonStyled primary onClick={() => this.setState({formOn: true})} className="btn btn--main" icon="verified_user">Dodaj rozstrzygnięcie</ButtonStyled>
                    </GridCustom>
                )}
                {onlyAdmin && this.state.previewOn && this.renderPreview()}
            </>
        )
    }

    renderMyOfferButton() {
        if (this.state.pakietyOfert.filter(
                po => po.ktoUtworzyl.id === this.props.user.id).length) {
            return (
                <GridCustom flexEnd>
                    <ButtonStyled primary onClick={this.handleMyOfferButtonClick}>
                        Szczegóły mojej oferty
                    </ButtonStyled>
                </GridCustom>
            )
        }
        return null
    }

    renderContent() {
        const zamowienia = this.props.ogloszenie.zamowienia;
        if (zamowienia.length > 1) {
            return zamowienia.map(z => this.renderZamowienie(z, true))
        }
        return this.renderZamowienie(zamowienia[0], false)
    }

    renderZamowienie(zamowienie, isMultiply) {
        return (
            <div key={zamowienie.id} className="offer__list">
                <div className="offer-resolve__name">
                    <Text tag="h2" sectionHeader>Lista złożonych ofert{isMultiply && ` do części:`}</Text>
                    {isMultiply && <Text label>{zamowienie.tytul}</Text>}
                </div>
                {this.renderOferty(zamowienie, isMultiply)}
            </div>
        )
    }

    renderOferty(zamowienie, isMultiply) {
        let oferty = [];
        for (let po of this.state.pakietyOfert) {
            for (let o of po.oferty) {
                if (o.zamowienieNode.id === zamowienie.zamowienieNode.id) {
                    let oferta = Object.assign({}, o, {
                        oferentId: po.ktoUtworzyl.id,
                        danePodmiotu: po.danePodmiotu,
                        zalaczniki: po.zalaczniki,
                        pakietId: po.id
                    });
                    oferty.push(oferta);
                    break
                }
            }
        }
        if (!oferty.length) {
            return (
                <div className="offer__list">
                    <Text info className="text--counter">Liczba wyników: 0</Text>
                    <Text error>Nie złożono żadnej oferty{isMultiply ? ` do części: ${zamowienie.tytul}` : ''}</Text>
                </div>
            )
        }
        return (
            <>
                <Text info>Liczba wyników: {oferty.length}</Text>
                <GridCustom tag="ul">
                    {oferty.sort(this.sortOferty).map(o => this.renderOferta(o))}
                </GridCustom>
            </>
        )
    }

    renderOferta(oferta) {
        const userId = this.props.user.id;
        const showMyOfferLabel = this.props.isAdvertisementOwner ? false : (
            !!userId ? oferta.oferentId === userId : false);
        return this.renderOfertaBox(oferta, showMyOfferLabel)
    }

    renderOfertaBox(oferta, showMyOfferLabel, isSelected=false) {
        return <OfertaBox
                   className={isSelected ? 'box--selected' : ''}
                   isSelected={isSelected}
                   key={oferta.id}
                   oferta={oferta}
                   showAttachments={this.showAttachments}
                   showFullVariants={this.showFullVariants}
                   showMyOfferLabel={showMyOfferLabel} />
    }

    renderPreview() {
        return <OfertaPreview
            ogloszenieId={this.props.ogloszenie.ogloszenie.id}
            onClosePreview={this.handleClosePreview} />
    }
}


export { OfertyOwnerClosedStatus };
