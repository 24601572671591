import React, { useState } from 'react';

import { GridCustom, Select, Text, ButtonStyled } from '../../common';
import { customSelectStyles } from '../../vars/vars';
import { saveFileFromApi } from '../../../helpers';


const EMPTY_VALUE = {
    value: 0,
    label: 'Wybierz',
};

const ZalacznikiSelect = (props) => {
    const [selectedZalacznik, setSelectedZalacznik] = useState(EMPTY_VALUE);
    const [selectWrapperClassName, setSelectWrapperClassName] = useState(null)
    
    if (props.zalaczniki.length === 0) {
        return <Text info>Liczba wyników: 0</Text>
    }
    
    let options = [EMPTY_VALUE, ].concat(...props.zalaczniki.map(z => {
        let label = z.nazwa;
        if (props.label && z[props.label.attrName] === true) {
            label += ` - ${props.label.description}`;
        }
        return {value: z.id, label, plik: z.plik}
    }))

    const downloadAllAttachments = () => {
        const url = `/api/offerset/${props.offersetId}/attachments.zip`;
        const fileName = `Załączniki-${props.offersetId}.zip`;

        return saveFileFromApi(url, fileName);
    }

    return (
        <>
            <GridCustom
                noWrap
                flexBottom
                className={selectWrapperClassName}
            >
                <Select
                    aria-label="Załączniki"
                    className="select-custom select-custom--download"
                    noOptionsMessage={() => 'Brak wybranej opcji'}
                    options={options}
                    placeholder="Brak załączników"
                    screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                    styles={customSelectStyles}
                    value={selectedZalacznik}
                    onChange={selectedOption => setSelectedZalacznik(selectedOption)}
                    menuShouldScrollIntoView
                    onFocus={() => setSelectWrapperClassName('select-download-wrapper')}
                    onBlur={() => setSelectWrapperClassName(null)}
                />
                {selectedZalacznik.value !== 0 && selectedZalacznik.plik.uri.length > 0 && (
					<ButtonStyled className="btn--pdf-dowload" onClick={(ev) => saveFileFromApi(selectedZalacznik.plik.uri, selectedZalacznik.plik.nazwa)}>
						Pobierz<span className="sr-only"> załącznik {selectedZalacznik.nazwa || selectedZalacznik.plik.nazwa}</span>
					</ButtonStyled>
                )}
                {props.offersetId != null && (
                    <ButtonStyled className="btn--pdf-dowload" onClick={(ev) => downloadAllAttachments()}>
                        Pobierz wszystkie załączniki
                    </ButtonStyled>
                )}

                {(selectedZalacznik.value !== 0 && !selectedZalacznik.plik.uri) && (
                    <Text error id="selectedZalacznik_error">Tego pliku nie da się pobrać. Skontaktuj się z obsługą strony.</Text>
                )}
            </GridCustom>
        </>
    )
}

export { ZalacznikiSelect };
