import React from 'react';

import { getPricesRange } from '../../../helpers';
import { Box, FieldWithLabel, Text } from '../../common';
import { ZalacznikiSelect } from '../common';


const OfertaBox = (props) => {
    const { className, oferta } = props;
    const { danePodmiotu, warianty } = oferta;

    let myOfferLabel = null;
    if (props.showMyOfferLabel) {
        myOfferLabel = <Text className="offer__my-offer-label">Moja oferta</Text>;
    }

    let cena;
    let opis = null;
    if (props.showFullVariants) {
        if (warianty.length > 1) {
            cena = (
                <ul>
                    {warianty.map((w, i) => (
                        <li key={i} className="variant">
                            <Text className="variant__price">{i + 1}. {w.cena} PLN</Text>
                            <Text className="long-text variant__content">{w.opis}</Text>
                        </li>
                    ))}
                </ul>
            );
        } else {
            cena = `${warianty[0].cena} PLN`;
        }
        if (props.isSelected && warianty[0].opis.length > 0) {
            opis = (
                <FieldWithLabel label="Opis" tag="label">
                    <Text className="long-text">{warianty[0].opis}</Text>
                </FieldWithLabel>
            )
        }
    } else {
        if (warianty.length > 1) {
            const prices = getPricesRange(warianty.map(w => w.cena))
            cena = `${prices.min} - ${prices.max} PLN (${warianty.length} warianty)`;
        } else {
            cena = `${warianty[0].cena} PLN`;
        }
    }

    let zalaczniki = null;
    if (props.showAttachments) {
        zalaczniki = (
            <FieldWithLabel
                label="Załączniki"
                tag="label"
                selectRef={oferta.zalaczniki.length > 0 ? React.createRef() : null}>
                <ZalacznikiSelect zalaczniki={oferta.zalaczniki} offersetId={oferta.pakietId}/>
            </FieldWithLabel>
        );
    }

    const adres = danePodmiotu.adres || {};
    return (
        <Box
            flexiblewidth
            className={className}
            tag="li"
            titleOneRow={(
                <>
                    Oferta {danePodmiotu.nazwa}
                    {myOfferLabel}
                </>
            )}
        >
            {oferta.isRefused && <Text error><span className="sr-only">Ta oferta jest </span>Odrzucona</Text>}
            <>
                <FieldWithLabel label="Nazwa podmiotu" tag="label">
                    <Text>{danePodmiotu.nazwa}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Miejscowość" tag="label">
                    <Text>{`${adres.miejscowosc || ''}${adres.kraj === 'Polska' ? '' : ', ' + (adres.kraj || '')}`}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Cena" tag="label">{cena}</FieldWithLabel>
                {opis}
                {zalaczniki}
            </>
        </Box>
    )
};


export { OfertaBox }
